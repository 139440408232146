import React from 'react';


import './CheckBox.scss';
import { ReactComponent as CheckIcon } from './CheckBoxTik.svg';



type CheckBoxTypes = {
  checked?: boolean,
  onClick: Function,
}

export default function CheckBox({checked, onClick}: CheckBoxTypes) {
  return (
    <div className={`pdlCheckBox ${checked && 'pdlCheckBox--checked'}`} onClick={(e_) => onClick()}>
      {checked && <CheckIcon className="pdlIcon"/>}
    </div>
  );
}
