import React from 'react';


import './IconButton.scss';
import IconComponent, { SVGIconNames } from '../IconComponent';

type IconButtonProps = {
  name: SVGIconNames,
  size?: 'small' | 'large' | 'medium',
  primary?: boolean,
}

export default function IconButton({ name, size, primary }: IconButtonProps) {
  return (
    <div className={`pdlIconButton ${primary && 'pdlIconButton--primary'}`}>
      <IconComponent name={name} className={`pdlIcon ${primary && 'pdlIcon--primary'}`}/>
    </div>
  )
}
