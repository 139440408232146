import React, { useState, useRef, useLayoutEffect } from 'react';


import './ProfilePic.scss';
import Button from '../../../components/Button/Button';
import IconComponent from '../../../components/IconComponent';
import Avatar from '../../../components/Avatar/Avatar';


type ProfilePicTypes = {
  onSuccess: Function,
}

export default function ProfilePic({ onSuccess }: ProfilePicTypes) {
  const [ uploadedImage, setUploadedImage ] = useState<string | undefined>(undefined);

  return (
    <div className="pdlProfilePic">

      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className="pdlPhotoContainer">
          {uploadedImage === undefined ?
            <IconComponent name="camera_fill" className="pdlIcon"/>
            :
            <Avatar src={uploadedImage} h={174} w={174} rounded/>
          }
        </div>
      </div>


      <div style={{height: 36}}>{/**just for space*/}</div>

      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Uploader label={uploadedImage === undefined ? 'Upload Photo' : 'Change Photo'} onUploadImage={setUploadedImage}/>
      </div>

      <div style={{height: 100}}>{/**just for space*/}</div>

      <Button block label="Get Started" onClick={onSuccess}/>
    </div>
  );
}

function Uploader({ label, onUploadImage }:{ label: string, onUploadImage: Function }) {
  const inputFile = useRef<HTMLInputElement>(null);


  useLayoutEffect(() => {

  });

  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    if (!file) { return; }

    const fileUrl = URL.createObjectURL(file);
    onUploadImage(fileUrl);
  };

  return (
    <div>
      <input
        type="file"
        style={{display: 'none'}}
        onChange={handleUpload}
        ref={inputFile}
      />
      <div style={{alignSelf: 'center'}}>
        <Button wider outline label={label} onClick={() => {
          inputFile.current?.click();
        }}/>
      </div>
    </div>
  );
}
