import React, { useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import './DesignLibrary.scss';
import Text from '../../components/Text/Text';
import Button from '../../components/Button/Button';
import Container from '../../components/Layout/Container';
import Carousel from '../../components/Carousel/Carousel';
import HappyFaceSlider from '../../components/HappyFaceSlider/HappyFaceSlider';
import CategorySlider from '../../components/CategorySlider/CategorySlider';
import IconButton from '../../components/IconButton/IconButton';
import PageFoundation, { PageArea } from '../../components/Layout/PageFoundation';
import Avatar from '../../components/Avatar/Avatar';
import SearchBar from '../../components/Misc/SearchBar/SearchBar';
import LocationPicker from '../../components/Misc/LocationPicker/LocationPicker';
import InlineInput from '../../components/InlineInput/InlineInput'

function Gap({ s, m, l }: {s?: boolean, m?: boolean, l?: boolean}) {
  return (
    <div style={{ marginTop: s ? 16 : m ? 32 : l ? 64 : 20}}>

    </div>
  )
}

function TextExamples() {
  return (
    <>
      <Text bp fontSize={20}> Header one </Text>
      <Text b80 fontSize={20} fontWeight={500}> Header two </Text>
    </>
  );
}


function ButtonExamples() {
  return (
    <>
      <Button label="Basic"/>
      <Gap/>
      <Button label="Basic" disabled/>
      <Gap/>
      <Button label="Wider" wider/>
      <Gap/>
      <Button label="Wider" wider disabled/>
      <Gap/>
      <Button label="outline" outline/>
      <Gap/>
      <Button label="oWider" outline wider/>
      <Gap/>
      <Button label="block" block wider/>
      <Gap/>
      <Button label="block" block wider disabled/>

      <Gap/>
      <Button label="Negotiate" warning/>
      <Gap/>
      <Button label="Negotiate Wider" wider warning/>
      <Gap/>
      <Button label="nOutline" outline warning/>
      <Gap/>
      <Button label="nOWider" outline wider warning/>
      <Gap/>
      <Button label="block" block warning/>


      <Gap/>
      <Button label="Basic small" s/>
      <Gap/>
      <Button label="Wider small" wider s/>
      <Gap/>
      <Button label="outline small" outline s/>
      <Gap/>
      <Button label="oWider small" outline wider s/>
      <Gap/>
      <Button label="oWider small block" outline block s/>

      <Gap/>
      <Button label="Negotiate small" warning s/>
      <Gap/>
      <Button label="Negotiate Wider small" wider warning s/>
      <Gap/>
      <Button label="nOutline small" outline warning s/>
      <Gap/>
      <Button label="nOWider small" outline wider warning s/>
      <Gap/>
      <Button label="nOWider small block" outline block warning s/>



      <Gap/>
      <Button label="Basic Large" l/>
      <Gap/>
      <Button label="Wider large" wider l/>
      <Gap/>
      <Button label="outline large" outline l/>
      <Gap/>
      <Button label="oWider large" outline wider l/>
      <Gap/>
      <Button label="oWider large" outline block l/>

      <Gap/>
      <Button label="Negotiate large" warning l/>
      <Gap/>
      <Button label="Negotiate Wider large" wider warning l/>
      <Gap/>
      <Button label="nOutline large" outline warning l/>
      <Gap/>
      <Button label="nOWider large" outline wider warning l/>
      <Gap/>
      <Button label="nOWider large block" outline block warning l/>
      <Gap l/>
    </>
  );
}


function IconButtonExamples() {
  return (
    <>
      <IconButton name="arrow_left"/>
      <Gap/>
      <IconButton name="arrow_right"/>
      <Gap/>
      <IconButton name="arrow_left" primary/>
      <Gap/>
      <IconButton name="arrow_right" primary/>
    </>
  )
}


function CarouselExamples() {
  return (
    <>
      <Carousel/>
    </>
  );
}


function SliderExamples() {
  return (
    <>
      <Container>
        <div style={{backgroundColor: '#C0C0C0', height: 230, width: '100%', padding: '20px 30px 20px 20px'}}>
          <CategorySlider/>
        </div>
        <div style={{marginTop: 20}}></div> {/** just for space */}
        <HappyFaceSlider/>
      </Container>
    </>
  );
}


function AvatarExamples() {
  return (
    <>
      <Container>
        <Gap/>
        <Avatar bg rounded h={116} w={116} src={'https://outpostrecruitment.com/wp-content/uploads/2019/05/Ruairi-Spillane.png'}/>
        <Gap/>
        <Avatar disabled bg rounded h={116} w={116} src={'https://outpostrecruitment.com/wp-content/uploads/2019/05/Ruairi-Spillane.png'}/>
        <Gap/>
        <Avatar disabled rounded h={64} w={64} src={'https://bpconf.com/wp-content/uploads/profile/1011.jpg'}/>
        <Gap/>
        <Avatar disabled rounded h={240} w={240} src={'https://www.tribeofnoise.com/media/images/profile/44398/1475730433.jpg'}/>
        <Gap/>
        <Avatar rounded h={240} w={240} src={'https://www.tribeofnoise.com/media/images/profile/44398/1475730433.jpg'}/>
        <Gap/>
        <Avatar disabled rounded h={64} w={64} src={'https://outpostrecruitment.com/wp-content/uploads/2019/05/Ruairi-Spillane.png'} onClick={()=>{}}/>
        <Gap/>
        <Avatar rounded h={64} w={64} src={'https://bpconf.com/wp-content/uploads/profile/1011.jpg'} onClick={()=>{}}/>
        <Gap/>
        <Avatar rounded disabled h={64} w={64} src={'https://bpconf.com/wp-content/uploads/profile/1011.jpg'} onClick={()=>{}}/>
      </Container>
    </>
  );
}


function LocationPickerExamples() {
  return (
    <>
      <Container>
        <div style={{backgroundColor: '#C0C0C0', height: 110, width: '100%', padding: '20px 30px 20px 20px'}}>
          <LocationPicker locationName="Albert Canada"/>
        </div>
        <Gap/>
        <div style={{height: 84, width: 400, padding: '20px 30px 20px 20px'}}>
          <LocationPicker locationName="Albert Canada" s/>
        </div>
      </Container>
    </>
  )
}
function SearchBarExamples() {
  const [ v, setV ] = useState<string>('');

  return (
    <>
      <Container>
        <div style={{backgroundColor: '#C0C0C0', height: 104, width: 600, padding: '20px 30px 20px 20px'}}>
          <SearchBar v={v} onChange={setV}/>
        </div>
        <Gap/>
        <div style={{height: 84, width: 400, padding: '20px 30px 20px 20px'}}>
          <SearchBar v={v} onChange={setV} s/>
        </div>
      </Container>
    </>
  )
}
 
function InlineInputExamples(){
  const [ passwordType, setPasswordType ] = useState<'text' | 'password'>('password');

  const iconRightOnClick = () =>{
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  }
  return(
    <div>
      <InlineInput type="text" placeholder="Enter your email address" label="Email"/>
      <div style={{marginTop: 20}}></div> {/** just for space */} 
      <InlineInput type="tel" placeholder="" label="Mobile Phone"/>
      <div style={{marginTop: 20}}></div> {/** just for space */} 
      <InlineInput type={passwordType} placeholder="Enter your password" label="Password" iconRight={passwordType === 'password' ? 'eye_slash' : 'eye'} iconRightOnClick={iconRightOnClick}/>
    </div>
  );
}



const componentsToShow = [
  'texts', 'buttons', 'icon-buttons', 'carousel', 'sliders', 'avatars',
  'location-pickers', 'search-bars', 'inline-input', 
];

export default function DesignLibrary() {
  const { component } = useParams<any>();
  const history = useHistory();

  const leftPane = (
    <div className="pdlLeftPane">
      <div className="pdlHeaderWrapper">
        <Text fontSize={20}> Design Library </Text>
      </div>
      <hr></hr>
      {componentsToShow.map((cat) => (
        <div
          className="pdlMenuNameWrapper"
          onClick={() => { history.push(`/design-library/${cat}`) }}>
          <Text primary={component?.toString() === cat.toString()}> {cat} </Text>
        </div>
      ))}
    </div>
  );

  const rightPane = (
    <div className="pdlRightPane">
      <div className="pdlHeaderWrapper">
        <Text fontSize={28}> {component} </Text>
      </div>
      <hr></hr>
      {component === 'texts' && <TextExamples/>}
      {component === 'buttons' && <ButtonExamples/>}
      {component === 'carousel' && <CarouselExamples/>}
      {component === 'sliders' && <SliderExamples/>}
      {component === 'icon-buttons' && <IconButtonExamples/>}
      {component === 'avatars' && <AvatarExamples/>}
      {component === 'location-pickers' && <LocationPickerExamples/>}
      {component === 'search-bars' && <SearchBarExamples/>}
      {component === 'inline-input' && <InlineInputExamples/>}
    </div>
  );

  return (
    <div className="pdlDesignLibrary">
      <PageFoundation>
        <PageArea size={2}>
          {leftPane}
        </PageArea>
        <PageArea size={10} scrollable scrollbarHide>
          <Container>
            {rightPane}
          </Container>
        </PageArea>
      </PageFoundation>
    </div>
  );
}
