import React, { useContext, useEffect, useRef } from 'react';


import './MessageListContainer.scss';
import Text from '../../../../components/Text/Text';
import Message from './components/Message/Message';
import { MessengerContext } from '../../../../contexts/MessengerContext';
import QuoteMessageContainer from './components/QuoteMessageContainer/QuoteMessageContainer';
import OfferMessage from './components/OfferMessage/OfferMessage';


export default function MessageListContainer() {
  const { messages } = useContext(MessengerContext)!;
  const scrollToMe = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log('Messages updted!');
    scrollToMe.current!.scrollIntoView({ behavior: 'smooth' });
  }, [ messages ]);

  return (
    <div className="pdlMessageListContainer">

      {messages.map(({type, text, senderAvatar, time, isMyMessage}: any) =>
        type === 'quote' ?
          <QuoteMessageContainer>
            <Text fontSize={18} b50>Sara quoted the price $180 for you.</Text>
            <Text fontSize={18} b50>Will you place the order or negotiate?</Text>
          </QuoteMessageContainer>
        :
        type === 'sent-offer' ?
          <QuoteMessageContainer>
            <Text fontSize={18} b50>You offered Sara $100</Text>
            {''}
          </QuoteMessageContainer>
        :
        type === 'declined-offer' ?
        <QuoteMessageContainer>
          <Text fontSize={18} b50>Sara declined your offer</Text>
          <Text fontSize={18} b50>Do you want to place order at the current price or renegotiate?</Text>
          {''}
        </QuoteMessageContainer>
        :
        type === 'offer-accepted' ?
        <QuoteMessageContainer>
          <Text fontSize={18} b50>You accepted Sara's offer</Text>
          <Text fontSize={18} b50>Please click on place order to confirm Sara</Text>
          {''}
        </QuoteMessageContainer>
        :
        type === 'offer' ?
          <OfferMessage/>
        :
        <Message text={text} senderAvatar={senderAvatar} time={time} myMessage={isMyMessage}/>
      )}
      <div ref={scrollToMe}></div>
    </div>
  );
}
