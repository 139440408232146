import React, { useState } from 'react';


import './HappyFaceSlider.scss';
import Container from '../Layout/Container';
import Grid, { GridItem } from '../Layout/Grid';
import Text from '../Text/Text';
import IconComponent from '../IconComponent';
import IconButton from '../IconButton/IconButton';


export default function HappyFaceSlider() {
  const [ active, setActive ] = useState<number>(0);

  const swipeLeft = () => {
    setActive((((active-1)%data.length)+data.length)%data.length);
  };

  const swipeRight = () => {
    setActive((active+1)%data.length);
  };

  return (
    <div className="pdlHappyFaceSlider">
        <Grid>
          <GridItem size={2}>{/** Just for gap */}</GridItem>
          <GridItem size={8}>
            <div className="pdlMain">
              <div className="pdlContents">
                <Grid>
                  <GridItem size={1}>
                    <IconComponent name="happy_face_quote_start" className=""/>
                  </GridItem>
                  <GridItem size={10}>
                    <div className="pdlParagraphContainer">
                      <Text vw fontSize={20}>
                        {data[active].p}
                      </Text>
                    </div>
                  </GridItem>
                  <GridItem size={1}>
                    <IconComponent name="happy_face_quote_end" className="pldQuoteEndIcon"/>
                  </GridItem>
                </Grid>
                <div style={{marginTop: 32}}>{/** TODO: just for space, move to scss */}</div>
                <Text vw fontSize={28} fontWeight={600}>
                  {data[active].name}
                </Text>
                <Text vw fontSize={24}>
                  {data[active].designation}
                </Text>
              </div>
            </div>
            <div className="pdlShifterButtonArea" onClick={swipeLeft}>
              <IconButton name="arrow_left" primary/>
            </div>
            <div className="pdlShifterButtonArea pdlShifterButtonArea--right" onClick={swipeRight}>
              <IconButton name="arrow_right" primary/>
            </div>
          </GridItem>
        </Grid>
    </div>
  );
}

const data = [{
  p: 'Sometimes it\'s simply better to ignore the haters. \
    That\'s the lesson that Tom\'s dad had been trying to teach him.',
  name: 'K.M. Waliullah',
  designation: 'Product Designer at Inflack',
}, {
  p: 'Sitting in the sun, away from everyone who had done him harm \
  in the past, he quietly listened to those who roamed by.',
  name: 'Washeef Mohammad',
  designation: 'CTO of Provisorr',
}, {
  p: 'He looked at the sand. Picking up a handful, he wondered how \
   many grains were in his hand. Hundreds of thousands?',
  name: 'Mohaimen Jim',
  designation: 'CEO or Provisorr',
}];
