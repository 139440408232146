import React from 'react';


import './Text.scss';


type TextProps = {
  children: string | any,
  vw?: boolean,
  fontSize?: number,
  fontWeight?: number,
  lineHeight?: string, // string (px / %)
  centered?: boolean,
  bp?: boolean, // color black-primary
  b80?: boolean, // color black-80
  b50?: boolean, // color black-50
  white?: boolean, // color
  primary?: boolean,
  success?: boolean,
  danger?: boolean,
  onClick?: Function,
};

export default function Text({ children, vw, fontSize, fontWeight, lineHeight, centered, bp, b80, b50, white, primary, success, danger, onClick }: TextProps) {

  const paragraphClass = `pdlText ${onClick && 'pdlText--clickable'} ${bp && 'pdlText--bp'} ${b80 && 'pdlText--b80'} ${b50 && 'pdlText--b50'} ${white && 'pdlText--white'} ${primary && 'pdlText--primary'} ${success && 'pdlText--success'} ${danger && 'pdlText--danger'}`;

  let pStyle = {};
  if (fontSize)   pStyle = { ...pStyle, fontSize: vw ? `${((fontSize*10)/192)}vw` : fontSize };
  if (fontWeight) pStyle = { ...pStyle, fontWeight };
  if (lineHeight) pStyle = { ...pStyle, lineHeight };
  if (centered)   pStyle = { ...pStyle, textAlign: 'center' };

  return <p style={pStyle} className={paragraphClass} onClick={(e) => onClick?.()}> {children} </p>;
}
