
import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import './App.scss';
import { UiContextProviderWrapper } from './contexts/UiContext';
import { MessengerContextProviderWrapper } from './contexts/MessengerContext';
import { RouterSwitchWrapper } from './RouterConfig';
import { BackendContextProviderWrapper } from './contexts/BackendContext';




export default function App() {
  return (
    <UiContextProviderWrapper>
      <BackendContextProviderWrapper>
        <MessengerContextProviderWrapper>
          <BrowserRouter>
            <div className="pdlApp">
              <RouterSwitchWrapper switchFor='app'/>
            </div>
          </BrowserRouter>
        </MessengerContextProviderWrapper>
      </BackendContextProviderWrapper>
    </UiContextProviderWrapper>
  );
}
