import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';




type UserRoles = 'user' | 'service_provider';

interface UserEntity {
  name: string,
  role: UserRoles,
};






type BackendContextType = {
  user: UserEntity | undefined,
  tempToggleUserRole: Function,
}

const BackendContext = React.createContext<BackendContextType | undefined>(undefined);

type BackendContextProviderWrapperProps = { children: JSX.Element };

function BackendContextProviderWrapper({children}: BackendContextProviderWrapperProps) {
  const [ user, _setUser ] = useState<UserEntity | undefined>(undefined);

  useEffect(() => {
    _setUser({
      name: 'John Doe',
      role: 'user',
    }); // Demo User

    return () => {
    }
  }, []);

  useEffect(() => {
    console.log( user?.role );

    return () => {
    }
  }, [ user ]);

  const tempToggleUserRole = () =>
    _setUser((currentValue: any) => {
      return {...currentValue, role: currentValue.role === 'user' ? 'service_provider' : 'user'};
    });


  return (
    <BackendContext.Provider value={{
      user,
      tempToggleUserRole,
    }}>
      {children}
    </BackendContext.Provider>
  );
}

export {
  BackendContext,
  BackendContextProviderWrapper,
}

export type {
  BackendContextType,
};
