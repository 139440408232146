import React, { useState } from 'react';
import OtpInput from "react-otp-input";

import './OTP.scss';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';


type OTPTypes = {
  onSuccess: Function,
}

export default function OTP({ onSuccess }: OTPTypes) {
  const [ theOTP, setTheOTP ] = useState<string>('');

  return (
    <div className="pdlOTP">
      <Text
        fontSize={30}
        fontWeight={500}
        centered>
        One Time Password ( OTP )
      </Text>
      <div className="pdlHeaderNextGap">{/** only for gap*/}</div>
      <OtpInput
        value={theOTP}
        onChange={(otp: any) => setTheOTP(otp)}
        numInputs={6}
        inputStyle={{width: 70}}
        focusStyle={{width: 70}}
        separator={<span className="pdlSeparator"> </span>}
      />
      <div className="pdlResendOTPButtonContainer">
        <Text fontSize={14} lineHeight="21px" bp onClick={() => {}}>
          Resend OTP ?
        </Text>
      </div>
      <Text centered fontSize={14} lineHeight="21px" b50>
        *Enter 6 digit One Time Password(OTP) number that already send to your phone number
      </Text>
      <div className="pdlButtonPreviousGap">{/** only for gap*/}</div>
      <Button block disabled={theOTP.length < 6} label="VERIFY" onClick={onSuccess}/>
    </div>
  );
}
