import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';

import './ProfileDrawer.scss';
import { MessengerContext } from '../../../contexts/MessengerContext';
import Text from '../../../components/Text/Text';
import UserReviews from './components/UserReviews/UserReviews';


export default function MessengerDrawer() {
  const { isProfileDrawerShown, setIsProfileDrawerShown } = useContext(MessengerContext)!;

  return (
    <div className="pdlProfileDrawer">
      <Modal
        dialogClassName="pdl-profile-drawer-modal-dialog"
        contentClassName="pdl-profile-drawer-modal-content"
        show={isProfileDrawerShown}
        onHide={() => setIsProfileDrawerShown(false)}
        size="lg"
      >
        <Modal.Header className="pdl-profile-drawer-modal-header">
          <div style={{textAlign: 'center', width: '100%'}}>
            <Text centered fontSize={24} fontWeight={600}>
              Profile
            </Text>
          </div>
        </Modal.Header>
        <Modal.Body>
          <UserReviews/>
        </Modal.Body>
      </Modal>
    </div>
  );
}