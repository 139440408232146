import React, { useState } from 'react';


import './SkillSelection.scss';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';
import CheckBox from '../../../components/CheckBox/CheckBox';


type SkillSelectionTypes = {
  onSuccess: Function,
}

export default function SkillSelection({ onSuccess }: SkillSelectionTypes) {

  return (
    <div className="pdlSkillSelection">

      <div style={{height: 16}}>{/**  */}</div>

      <Text
        fontSize={30}
        fontWeight={500}
        centered>
        Select your skills
      </Text>

      <hr style={{marginBottom: 0}}></hr>

      <div className="pdlSkillListContainer">
        {[
          'First SKill',
          'Second',
          'third',
          'forth',
          'fifth',
          'sixth',
          'Seventh',
          'Eighth',
          'Ninth',
          'Tenth',
          'Eleventh',
          '12\'th',
          '13\'th',
          '14\'th',
          '15\'th',
          '16\'th',
        ].map((label) => <SkillItem label={label}/>)}
      </div>


      <div style={{padding: '0px 128px'}}>
        <Button block label="Save Skill" onClick={onSuccess}/>
      </div>
    </div>
  );
}

type SkillItemProps = {
  label: string,
}

function SkillItem({label}: SkillItemProps) {
  const [ selected, setSelected ] = useState<boolean>(false);

  return (
    <div className="pdlSkillItem">
      <Text>
        {label}
      </Text>

      <CheckBox
        checked={selected}
        onClick={() => setSelected(!selected)}
      />
    </div>
  );
}