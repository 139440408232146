import React from 'react';


import './Information.scss';
import Text from '../../../../../components/Text/Text';

function InlineInfo({ prop, val }: { prop: string, val: string }) {
  return (
    <div style={{ marginBottom: 16 }}>
      <strong> {prop} </strong> : {val}
    </div>
  );
}

function SeparatedInfo({ prop, val }: { prop: string, val: string }) {
  return (
    <div style={{ display: 'flex', marginBottom: 16 }}>

      <div style={{ flex: 4 }}>
        <Text fontWeight={600}>{prop}:</Text>
      </div>

      <div style={{ flex: 7 }}>
        <Text>{val}</Text>
      </div>

    </div>
  );
}

export default function Information() {
  return (
    <div className="pdl-Messenger-ProjectDetail-Modal-Information">
      <div className="pdlInlineInfos">
        <InlineInfo prop={'Sub-Category'} val={'Sofa, Carpet, Mattress & Furniture Cleaning'}/>
        {/* <InlineInfo prop={'Problem Tittle'} val={'Furniture Cleaning'}/> */}
        <InlineInfo prop={'Problem Description'} val={'I need a steam wash for my 1 love seat and 2 single recliners.'}/>
      </div>
      <div className="pdlNonInlineInfos">
        <SeparatedInfo prop={'Available Time'} val={'Sun, Tue, Thu (Afternoon and Evening)'}/>
        <SeparatedInfo prop={'Deadline'} val={'15 Feb, 2021'}/>
        <SeparatedInfo prop={'Budget (approx.)'} val={'$100'}/>
      </div>
    </div>
  );
}
