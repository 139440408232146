import React, { useState } from 'react';


import './Registration.scss';
import Form from './Form/Form';
import OTP from './OTP/OTP';
import SuccessMessage from './SuccessMessage/SuccessMessage';
import SkillSelection from './SkillSelection/SkillSelection';
import ProfilePic from './ProfilePic/ProfilePic';


export default function Registration() {
  const steps = [
    'form',
    'otp',
    'success-message',
    'skill-selection',
    'profile-pic',
  ];
  const [ currentStep, setCurrentStep ] = useState<number>(0);
  const goToNextStep = () => {
    if (currentStep === steps.length - 1) {
      /// TODO: should route to somewhere, for now just circle back
      setCurrentStep(0);
      return;
    }

    setCurrentStep(currentStep + 1);
  }

  return (
    <div className={`pdlRegistration ${steps[currentStep] !== 'form' && 'pdlRegistration--jumbotron-bg'}`}>
      {steps[currentStep] === 'form' && <Form onSuccess={goToNextStep}/>}
      {steps[currentStep] === 'otp' &&
        <SingleInputContainer>
          <OTP onSuccess={goToNextStep}/>
        </SingleInputContainer>
      }
      {steps[currentStep] === 'success-message' &&
        <SingleInputContainer>
          <SuccessMessage onSuccess={goToNextStep}/>
        </SingleInputContainer>
      }
      {steps[currentStep] === 'skill-selection' &&
        <SingleInputContainer skillSelection>
          <SkillSelection onSuccess={goToNextStep}/>
        </SingleInputContainer>
      }
      {steps[currentStep] === 'profile-pic' &&
        <SingleInputContainer>
          <ProfilePic onSuccess={goToNextStep}/>
        </SingleInputContainer>
      }
    </div>
  );
}

type SingleInputContainerProps = {
  children: React.ReactChild,
  skillSelection?: boolean,
};

function SingleInputContainer({ children, skillSelection }: SingleInputContainerProps) {
  return (
    <div className={`pdlSingleInputContainer ${skillSelection && 'pdlSingleInputContainer--skill-selector'}`}>
      {children}
    </div>
  );
}

