import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './PromotionalPage.scss'
import LOGO from './logo.svg';
import IMAGE_ONE from './1.svg';
import IMAGE_TWO from './2.svg';
import IMAGE_THREE from './3.svg';
import IMAGE_FOUR from './4.svg';
import IMAGE_FIVE from './5.svg';
import IMAGE_SIX from './6.svg';
import IMAGE_SEVEN from './7.svg';
import IMAGE_EIGHT from './8.svg';
import IMAGE_NINE from './9.svg';
import IMAGE_TEN from './10.svg';
import APP_STORE from './App-store-badge.png';
import CAPTURE from './Capture.png';
import GOOGLE_PLAY_BADGE from './google-play-badge.png';
import MAP_MOBILE from './map-mobile.png';
import MOBILE_FOOTER from './mobile-footer.svg';
import MOBILE_ONE from './mobile1.svg';
import DOT from './dot2.svg';
import PERCENTAGE from './percentage.svg';
import SCREEN_SHOOT_1 from './screen-shoot-1.svg';
import SCREEN_SHOOT_2 from './screen-shoot-2.svg';
import SCREEN_SHOOT_3 from './screen-shoot-3.svg';
import FOOTER_ONE from './footer-bg-1.svg'

import usePromotionalPage from './PromotionalPageHooks';


export default function PromotionalPageDesktop() {
  const {
    email,
    setEmail,
    joinedMailingList,
    onClickMailingListJoin,
  } = usePromotionalPage();

  return (
    <div className="promotionalPage">
      <nav className="nav">
        <div className="container-fluid nav-left-padding">
          <img src={LOGO}/>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 oc">
            <div className="col">
              <div className="container content" data-aos="fade-right">
                <h1 data-aos="fade-right">Who are we</h1>
                <p data-aos="fade-right">
                On Provisorr we connect customers to a pool of qualified
                Handymen and Service Providers. Customers select and chat with
                providers based on price, availability and review history.
                Providers bid on projects that fit their expertise and schedule,
                and ensure the completed work is up to the customer’s
                satisfaction.
                </p>
                <div className="dot">
                <img src={DOT} id="left-dot" />
                <img src={DOT} id="right-dot" />
                <div className="follow-email">
                  <p>
                    Get a $100 promotional credit when you sign up to <br />
                    our mailing list as an early founder when we launch.
                  </p>
                  <a href="#follow"> Follow us via email </a>
                  <div className="follow-head">
                    <p>Get a $100</p>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="d1">
              <img src={IMAGE_TWO} />
              <div className="d2">
                <img data-aos="fade-left" src={IMAGE_THREE} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid container-padding">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 data-aos="fade-left" data-aos-once="true">How it works</h1>
          </div>
        </div>
        <div className="row oc1 align-items-center">
          <div className="col-sm-5">
            <div className="row">
              <div className="col-sm-2 text-center" data-aos="fade-right"><img src={SCREEN_SHOOT_1}></img></div>
              <div className="col-sm-10">
                <h4 data-aos="slide-up">
                Post a problem by describing the problem and posting images.
                </h4>
              </div>
            </div>
          </div>
          <div className="col-sm-7" data-aos="slide-left">
            <img src={IMAGE_FOUR} className="option-img" />
          </div>
        </div>
        <div className="row oc1 align-items-center">
          <div className="col-sm-7" data-aos="slide-right">
            <img src={IMAGE_NINE} className="option-img" />
          </div>
          <div className="col-sm-5">
            <div className="row">
              <div className="col-sm-2 text-center" data-aos="fade-left"><img src={SCREEN_SHOOT_2}></img></div>
              <div className="col-sm-10">
                <h4 data-aos="slide-up">
                Get bids from qualified Service Providers. Add them to chat to
                discuss pricing, deadlines and additional project details.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row oc1 align-items-center">
          <div className="col-sm-5">
            <div className="row">
              <div className="col-sm-2 text-center" data-aos="fade-right"><img src={SCREEN_SHOOT_3}></img></div>
              <div className="col-sm-10">
                <h4 data-aos="slide-up">
                Confirm delivery of the service and leave a review and feedback
                for your professional.
                </h4>
              </div>
            </div>
          </div>
          <div className="col-sm-7" data-aos="slide-left">
            <img src={IMAGE_TEN} className="option-img" />
          </div>
        </div>
      </div>
      <div className="container-fluid container-padding">
        <div className="row">
          <div className="col-sm-12 text-center" data-aos="zoom-in">
            <h1>How we are different</h1>
          </div>
        </div>
        <div className="row oc2 align-items-center">
          <div className="col-sm-4" data-aos="slide-right">
            <img src={IMAGE_FIVE} className="diff-img" />
          </div>
          <div className="col-sm-8 col-padding">
            <div className="row row-padding-top">
              <div className="col-sm-2 text-center" data-aos="fade-right">
                <img src={IMAGE_SIX} className="round" />
                </div>
              <div className="col-sm-10" data-aos="zoom-in">
                <h4>
                Competitive Bidding means customers choose the best provider for
                the job at competitive prices from a marketplace of providers
                </h4>
              </div>
            </div>
            <div className="row row-padding-top">
              <div className="col-sm-2 text-center" data-aos="fade-right"><img src={IMAGE_SEVEN} className="round" /></div>
              <div className="col-sm-10" data-aos="zoom-in">
                <h4>
                We offer an escrow service that ensures both customers and
                Service Providers confirm the completion of the work. Both
                parties test and troubleshoot the work while the provider is on
                site before the work is considered complete
                </h4>
              </div>
            </div>
            <div className="row row-padding-top">
              <div className="col-sm-2 text-center" data-aos="fade-right"><img src={PERCENTAGE}></img></div>
              <div className="col-sm-10" data-aos="zoom-in">
                <h4>
                We charge no fees on our Service Providers. On Provisorr our
                providers work for themselves and are paid for the entirety of
                their work
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{  height: 300 }}></div>
      <section
        id="follow"
        className="footer-bg"
      >
         <img src={FOOTER_ONE} className="footer-relative"></img>
        <div className="footer-polished" data-aos="zoom-in">

          <div className="footer-polished-leftPane">
            { !joinedMailingList &&
              <div className="mail-polished">
                <p>Join Our Mailing List</p>
                <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <button className="btn btn-primary" onClick={(_) => onClickMailingListJoin()}>Join Us</button>
              </div>
            }
            { joinedMailingList &&
              <div className="mail-polished">
                <p>Thanks for Joining</p>
              </div>
            }
            <div style={{ height: 52 }}></div>
            <h2 className="">Android and iOS applications coming soon...</h2>
            <div style={{ height: 52 }}></div>
            <div className="row">
              <div className="col-sm-6">
                <img src={GOOGLE_PLAY_BADGE} className="store-icon-polished" />
              </div>
              <div className="col-sm-6">
              <img src={APP_STORE} className="store-icon-polished" />
              </div>
            </div>
          </div>
          <div className="footer-polished-rightPane">
            <img src={CAPTURE} style={{ height: 535, width: 263 }} />
          </div>
        </div>
      </section>
    </div>
  );
}
