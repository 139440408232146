import React, { useState } from 'react';


import './ChannelCardUser.scss';
import Avatar from '../../../../../components/Avatar/Avatar';
import Text from '../../../../../components/Text/Text';
import UserRating from '../../../../../components/UserRating/UserRating';
import Button from '../../../../../components/Button/Button';
import UserBadges from '../../../../../components/UserBadges/UserBadges';


function getRandomBoolean() {
  return (Math.floor(Math.random() * Math.floor(2)) % 2) === 1;
}

export default function ChannelCardUser() {
  const [ isAddedToChat, setAddedToChat ] = useState<boolean>(false);

  return (
    <div className={`pdlChannelCardUser ${isAddedToChat && 'pdlChannelCardUser--addedToChat'}`}>
      <div className="pdlAvatarContainer">
        <Avatar rounded h={36} w={36} src="https://www.tribeofnoise.com/media/images/profile/44398/1475730433.jpg"/>
      </div>

      <div className="pdlNameAndRaring">
        <div style={{height: 12}}>{/** Just for space */}</div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Text fontSize={16} white={isAddedToChat}>John Doe</Text>
          <UserBadges subscriptionOnly pro proPlus={getRandomBoolean()} white={isAddedToChat}/>
        </div>
        <div style={{height: 16}}>{/** Just for space */}</div>
        <UserRating white={isAddedToChat}/>
        <Text fontSize={14} white={isAddedToChat}>( Based on 45 orders )</Text>
        <div style={{height: 12}}>{/** Just for space */}</div>
      </div>

      <div className="pdlPriceAndOptions">
        <Text fontSize={24} fontWeight={600} centered white={isAddedToChat}>
          180$
        </Text>
        {!isAddedToChat && <div style={{height: 12}}></div>}
        {!isAddedToChat && <Button label="Add to Chat" onClick={() => setAddedToChat(true)}/>}
      </div>
    </div>
  );
}
