import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';

import './ProjectDetailDrawer.scss';
import { MessengerContext } from '../../../contexts/MessengerContext';
import Text from '../../../components/Text/Text';
import ProjectImages from './components/ProjectImages/ProjectImages';
import Information from './components/Information/Information';
import { BackendContext } from '../../../contexts/BackendContext';
import IconComponent from '../../../components/IconComponent';


export default function ProjectDetailDrawer() {
  const { user } = useContext(BackendContext)!;
  const { isProjectDetailDrawerShown, setIsProjectDetailDrawerShown } = useContext(MessengerContext)!;
  const onClickEditProjectDetail = () => {
    console.log('Project-Detail modal\'s edit icon clicked!');
    /*** TODO: not implemented */
  };

  return (
    <div className="pdlProjectDetailDrawer">
      <Modal
        dialogClassName={`pdl-project-detail-drawer-modal-dialog ${user?.role === 'service_provider' && 'pdl-project-detail-drawer-modal-dialog--right'}`}
        contentClassName="pdl-project-detail-drawer-modal-content"
        show={isProjectDetailDrawerShown}
        onHide={() => setIsProjectDetailDrawerShown(false)}
        size="lg"
      >
        <Modal.Header className="pdl-project-detail-drawer-modal-header">
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            <div>{/** empty div */}</div>
            <Text centered fontSize={24} fontWeight={600}>
              Project Details
            </Text>
            <IconComponent name="flat_icon_edit" className="pdlProjectDetailDrawer-edit-icon" onClick={onClickEditProjectDetail}/>
          </div>
        </Modal.Header>
        <Modal.Body className="pdl-project-detail-drawer-modal-body">
          <ProjectImages/>
          <Information/>
        </Modal.Body>
      </Modal>
    </div>
  );
}