import React from 'react';


import './UserReviews.scss';
import Text from '../../../../../components/Text/Text';
import Avatar from '../../../../../components/Avatar/Avatar';
import UserRating from '../../../../../components/UserRating/UserRating';
import UserBadges from '../../../../../components/UserBadges/UserBadges';


export default function UserReviews() {
  return (
    <div className="pdlUserReviews">

      <div className="pdlAvatarContainer">
        <Avatar rounded h={136} w={136} src="https://www.tribeofnoise.com/media/images/profile/44398/1475730433.jpg"/>
      </div>

      <Text
        fontSize={22}
        lineHeight="32px"
        fontWeight={500}
        centered
      >
        John doe
      </Text>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 16 }}>
        <UserBadges pro/>
      </div>

      <div className="pdlRatingContainer">
        <UserRating/>
      </div>

      <div style={{marginLeft: 12, marginBottom: 24}}>
        <Text
          fontSize={22}
          lineHeight="32px"
          fontWeight={500}
        >
          All Reviews
        </Text>
      </div>

      <div className="pdlReviewListContainer">
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
      </div>
    </div>
  );
}

function ReviewItem() {
  return (
    <div className="pdlReviewItem">
      <div className="pdlReviewerAvatar">
        <Avatar rounded h={36} w={36} src="https://i.pinimg.com/originals/c0/45/67/c04567a0834e7014a864d3a6b0b6db56.jpg"/>
      </div>
      <div className="pdlReviewerAvatar">
        <Text >
          Is this space enough for reviews?
        </Text>
      </div>
    </div>
  );
}
