import React from 'react';

import {
  Row as ReactBootstrapRow,
  Col as ReactBootstrapCol,
} from 'react-bootstrap';



type GridItemTyeps = {
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  children: JSX.Element[] | JSX.Element,
}

export function GridItem({ size, children }: GridItemTyeps) {
  let props = {};
  if (size) {
    props = { ...props, 'md': size };
  }

  return (
    <ReactBootstrapCol {...props}>
      {children}
    </ReactBootstrapCol>
  );
}


type GridTypes = {
  children: JSX.Element[] | JSX.Element,
}

export default function Grid({ children }: GridTypes) {
  return (
    <ReactBootstrapRow>
      {children}
    </ReactBootstrapRow>
  );
}
