import React, { useState, useContext } from 'react';

import {
  OverlayTrigger as ReactBootstrapOverlayTrigger,
  Tooltip as ReactBootstrapTooltip,
} from 'react-bootstrap';

import './BottomBar.scss';
import IconComponent from '../../../../components/IconComponent';
import { MessengerContext } from '../../../../contexts/MessengerContext';
import Text from '../../../../components/Text/Text';


export default function BottomBar() {
  const { dummySendMessage } = useContext(MessengerContext)!;
  const [ value, setValue ] = useState<string>('');
  const [ cameraHover, setCameraHover ] = useState<boolean>(false);
  const [ fileAttachHover, setFileAttachHover ] = useState<boolean>(false);
  const [ emojiHover, setEmojiHover ] = useState<boolean>(false);

  return (
    <div className="pdlBottomBar">
      <ReactBootstrapOverlayTrigger
        show={cameraHover}
        placement="top"
        overlay={
          <ReactBootstrapTooltip id="tooltip-top">
            <Text white>
              Upload Picture
            </Text>
          </ReactBootstrapTooltip>
        }
      >
        <div onMouseEnter={() => setCameraHover(true)} onMouseLeave={() => setCameraHover(false)}>
          <IconComponent name="flat_icon_camera" className="pdlIcon"/>
        </div>
      </ReactBootstrapOverlayTrigger>

      <ReactBootstrapOverlayTrigger
        show={fileAttachHover}
        placement="top"
        overlay={
          <ReactBootstrapTooltip id="tooltip-top">
            <Text white>
              Upload File
            </Text>
          </ReactBootstrapTooltip>
        }
      >
        <div onMouseEnter={() => setFileAttachHover(true)} onMouseLeave={() => setFileAttachHover(false)}>
          <IconComponent name="flat_icon_folder" className="pdlIcon"/>
        </div>
      </ReactBootstrapOverlayTrigger>

      <ReactBootstrapOverlayTrigger
        show={emojiHover}
        placement="top"
        overlay={
          <ReactBootstrapTooltip id="tooltip-top">
            <Text white>
              Emoji
            </Text>
          </ReactBootstrapTooltip>
        }
      >
        <div onMouseEnter={() => setEmojiHover(true)} onMouseLeave={() => setEmojiHover(false)}>
          <IconComponent name="emoji_smile_fill" className="pdlIcon"/>
        </div>
      </ReactBootstrapOverlayTrigger>

      <div style={{ width: 12 }}>{/** just for space */}</div>

      <MessageInput value={value} setValue={setValue}/>

      <IconComponent
        name="navigation_fill"
        className="pdlMessageSendIcon pdlIconSend"
        onClick={() => {
          value && dummySendMessage(value);
          setValue('');
        }}/>
    </div>
  );
}

function MessageInput({ value, setValue }: {value: string, setValue: Function}) {
  return (
    <div className="pdlMessageInput">
      <input
        placeholder="Write a message..."
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  )
}
