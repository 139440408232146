import React, { useState, useEffect } from 'react';

import {
  OverlayTrigger as ReactBootstrapOverlayTrigger,
  Card as ReactBootstrapCard,
  Dropdown as ReactBootstrapDropdown,
} from 'react-bootstrap';

import './SearchBar.scss';
import IconComponent from '../../IconComponent';
import Button from '../../Button/Button';


type SearchBarProps = {
  l?: boolean, // large (default)
  s?: boolean, // small
  v: string,
  onChange: Function,
};

export default function SearchBar({ l, s, v, onChange }: SearchBarProps) {
  const [focused, changeFocused] = useState<boolean>(false);

  const searchBarRef = React.createRef<HTMLDivElement>();
  const searchBarWidth = useResizeObserver(searchBarRef, 155);

  const results = {
    all: [
      'AC Service',
      'Home Cleaning',
      'Personal Beauty Care',
    ],
    separated: [
      'Separated link'
    ]
  };

  return (

      <ReactBootstrapOverlayTrigger
        show={focused}
        placement="bottom"
        overlay={
          <div className="pdlSearchResult">
            <ReactBootstrapCard style={{ width: searchBarWidth+22 }}>
              {results.all.map((result, index) =>
                <ReactBootstrapDropdown.Item eventKey={`${index+1}`}>
                  {result}
                </ReactBootstrapDropdown.Item>
              )}
              <ReactBootstrapDropdown.Divider />
              {results.separated.map((result, index) =>
                <ReactBootstrapDropdown.Item eventKey={`${results.all.length + index + 1}`}>
                  {result}
                </ReactBootstrapDropdown.Item>
              )}
            </ReactBootstrapCard>
          </div>
        }
      >
        <div className={`pdlSearchBar ${s && 'pdlSearchBar--sm'}`} ref={searchBarRef}>
          <input
            onChange={(e) => onChange(e.target.value)}
            type="text"
            value={v}
            placeholder={s ? 'Find your service here...' : 'Find your service here (Examples: AC, Fridge etc)'}
            onFocus={() => changeFocused(true)}
            onBlur={() => changeFocused(false)}
          />
          <Button label={<IconComponent name="search" className="pdlIcon"/>} s={s}/>
        </div>
      </ReactBootstrapOverlayTrigger>
  );
}

function useResizeObserver(elementRef: any, initialValue: number): number {
  const [ elementWidth, setElementWidth ] = useState(initialValue);

  const observer = React.useRef(new ResizeObserver((entries: any) => {
    const { width } = entries[0].contentRect;
    setElementWidth(width);
  }));

  useEffect(() => {
    if (elementRef.current) {
      observer.current.observe(elementRef.current);
    }

    return () => {
      // observer.current.unobserve(firstItemReference.current);
    };
  }, [observer, elementRef]);

  return elementWidth;
}
