import React, { useContext } from 'react';
import { UiContext } from '../../contexts/UiContext';


import PromotionalPageDesktop from './PromotionalPageDesktop';
import PromotionalPageMobile from './PromotionalPageMobile';


export default function PromotionalPageWrapper() {
  const { isDesktopOrLaptop } = useContext(UiContext)!;

  return (
    <div>
      {isDesktopOrLaptop ? <PromotionalPageDesktop/> : <PromotionalPageMobile/>}
    </div>
  );
}
