import React, { useEffect, useState } from 'react';


import './CategorySlider.scss';
import Text from '../../components/Text/Text';
import Container from '../Layout/Container';
import IconButton from '../IconButton/IconButton';
import IconComponent from '../IconComponent';
import { SVGIconNames } from '../IconComponent';


export default function CategorySlider() {
  const firstItemReference = React.createRef<HTMLDivElement>();

  const itemSize = useResizeObserver(firstItemReference, 155);

  const swipeLeft = () => {
    firstItemReference.current!.style.transition = 'all 1s';
    firstItemReference.current!.style.marginLeft = '0px';
  }

  const swipeRight = () => {
    firstItemReference.current!.style.transition = 'all 1s';
    firstItemReference.current!.style.marginLeft = `-${(itemSize+30)*3}px`;
  }

  return  (
    <div className="pdlCategorySlider">
      <Container fluid>
        <div className="row flex-row flex-nowrap mt-4 pb-4 pt-2 pdlScrollableArea">
          {itemData.map((x, index) =>
            <div className={`col-2`} ref={index ? undefined : firstItemReference}>
              <div className="pdlItem">
                <IconComponent name={x.icon} className="pdlIcon"/>
                <Text fontSize={14}>{x.text}</Text>
              </div>
            </div>
          )}
        </div>
        <div className="pdlShifterButtonArea" onClick={swipeLeft}>
          <IconButton name="arrow_left"/>
        </div>
        <div className="pdlShifterButtonArea pdlShifterButtonArea--right" onClick={swipeRight}>
          <IconButton name="arrow_right"/>
        </div>
      </Container>
    </div>
  );
}

function useResizeObserver(elementRef: any, initialValue: number): number {
  const [ elementWidth, setElementWidth ] = useState(initialValue);

  const observer = React.useRef(new ResizeObserver((entries: any) => {
    const { width } = entries[0].contentRect;
    setElementWidth(width);
  }));

  useEffect(() => {
    if (elementRef.current) {
      observer.current.observe(elementRef.current);
    }

    return () => {
      // observer.current.unobserve(firstItemReference.current);
    };
  }, [observer, elementRef]);

  return elementWidth;
}

const itemData: {icon: SVGIconNames, text: string}[] = [{
  icon: 'cat_food',
  text: 'Food',
},{
  icon: 'cat_rental',
  text: 'Rental',
},{
  icon: 'cat_photo',
  text: 'Photography',
},{
  icon: 'cat_beauty',
  text: 'Health & Beauty',
},{
  icon: 'cat_tailor',
  text: 'Tailor',
},{
  icon: 'cat_wedding',
  text: 'Wedding',
},{
  icon: 'cat_photo',
  text: 'Photography(v2)',
},{
  icon: 'cat_food',
  text: 'Food(v2)',
},{
  icon: 'cat_tailor',
  text: 'Tailor(v2)',
}];
