import React, { useContext } from 'react';


import './LeftPane.scss';
import Card from './components/Card/Card';
import SearchBar from './components/SearchBar/SearchBar';
import Filter from './components/Filter/Filter';
import ChannelCardUser from './components/ChannelCardUser/ChannelCardUser';
import { BackendContext } from '../../../contexts/BackendContext';
import ChannelCardWork from './components/ChannelCardWork/ChannelCardWork';




export default function LeftPane() {
  const { user } = useContext(BackendContext)!;

  return (
    <div className="pdlLeftPane">
      {user?.role === 'user' &&
      <div className="pdlCardContainer">
        <Card/>
      </div>
      }
      <div className="pdlSearchBarContainer">
        <SearchBar/>
      </div>
      <div className="pdlFiltersContainer">
        <Filter/>
      </div>
      {user?.role === 'user' ?
        <div className="pdlChannelListContainer">
          <ChannelCardUser/>
          <ChannelCardUser/>
          <ChannelCardUser/>
          <ChannelCardUser/>
          <ChannelCardUser/>
          <ChannelCardUser/>
          <ChannelCardUser/>
          <ChannelCardUser/>
          <ChannelCardUser/>
          <ChannelCardUser/>
        </div>
        :
        <div className="pdlChannelListContainer">
          <ChannelCardWork amount={150}/>
          <ChannelCardWork canBid onBid={() => {}}/>
          <ChannelCardWork amount={150} canRebid onRebid={() => {}}/>
          <ChannelCardWork canBid onBid={() => {}}/>
          <ChannelCardWork canBid onBid={() => {}}/>
          <ChannelCardWork amount={150} canRebid onRebid={() => {}}/>
          <ChannelCardWork amount={150} canRebid onRebid={() => {}}/>
          <ChannelCardWork canBid onBid={() => {}}/>
          <ChannelCardWork amount={150} canRebid onRebid={() => {}}/>
          <ChannelCardWork canBid onBid={() => {}}/>
        </div>
      }
    </div>
  );
}
