import React from 'react';

import {
  Container as ReactBootstrapContainer
} from 'react-bootstrap';


type ContainerProps = {
  fluid?: boolean,
  children: React.ReactNode,
}

export default function Container({ fluid, children }: ContainerProps) {
  let props = {};
  if (fluid) {
    props = { ...props, fluid: true };
  }

  return (
    <ReactBootstrapContainer {...props}>
      {children}
    </ReactBootstrapContainer>
  );
}
