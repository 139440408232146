import React from 'react';


import './OfferMessage.scss';
import Text from '../../../../../../components/Text/Text';
import Button from '../../../../../../components/Button/Button';


export default function OfferMessage() {
  return (
    <div className="pdl-Messenger-Message-OfferMessage">
      <Text fontSize={18}>Sara offered you $120</Text>
      <div style={{ display: 'flex', margin: 8 }}>
        <Button success label="Accept" onClick={() => {}}/>
        <div style={{ width: 8 }}>{/** just for space*/}</div>
        <Button danger label="Decline" onClick={() => {}}/>
      </div>
    </div>
  );
}