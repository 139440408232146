import React from 'react';

import './PageFoundation.scss';


type PageAreaTypes = {
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  children: React.ReactChild,
  scrollable?: boolean,
  scrollbarHide?: boolean,
}

export function PageArea({ size, children, scrollable, scrollbarHide }: PageAreaTypes) {

  return (
    <div className={`col-md-${size} pdlPageAreaContainer`}>
      <div className={`pdlPageArea ${scrollable && 'pdlPageArea--scrollable'} ${scrollbarHide && 'pdlPageArea--scrollbarHide'}`}>
        {children}
      </div>
    </div>
  );
}

type PageFoundationProps = {
  children: React.ReactChild | React.ReactChild[],
};

export default function PageFoundation({ children }: PageFoundationProps) {

  return (
    <div className="row pdlPageFoundation">
      {children}
    </div>
  );
}
