import React from 'react';


import './LocationPicker.scss';
import Text from '../../Text/Text';
import IconComponent from '../../IconComponent';


type LocationPickerProps = {
  s?: boolean,
  locationName: string,
};

export default function LocationPicker({ s, locationName }: LocationPickerProps) {
  return (
    <div className={`pdlLocationPicker ${s && 'pdlLocationPicker--sm'}`}>
      <IconComponent name="location_on" className="pdlIcon"/>
      <Text>{locationName}</Text>
    </div>
  );
}
