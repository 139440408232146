import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';


type SideBarActiveNavMenu = 'deposits' | 'profile' | 'help_center';

type UiContextType = {
  isDesktopOrLaptop: boolean,
  isBigScreen: boolean,

  scrollY: number,

  sideBarActiveNavMenu: SideBarActiveNavMenu,
  setSideBarActiveNavMenu: Function,
}

const UiContext = React.createContext<UiContextType | undefined>(undefined);

type UiContextProviderWrapperProps = { children: JSX.Element };

function UiContextProviderWrapper({children}: UiContextProviderWrapperProps) {
const [ sideBarActiveNavMenu, setSideBarActiveNavMenu ] = useState<SideBarActiveNavMenu>('deposits');

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isBigScreen = useMediaQuery({  query: '(min-width: 1824px)'  });

  const [ scrollY, setScrollY ] = useState<number>(0);

  const handleScrollY = () => setScrollY(window.scrollY);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollY);

    return () => {
      window.addEventListener('scroll', handleScrollY);
    }
  }, []);


  return (
    <UiContext.Provider value={{
      isDesktopOrLaptop,
      isBigScreen,
      scrollY,
      sideBarActiveNavMenu,
      setSideBarActiveNavMenu,
    }}>
      {children}
    </UiContext.Provider>
  );
}

export {
  UiContext,
  UiContextProviderWrapper,
}

export type {
  UiContextType,
};