import React from 'react';

import {
  Badge as ReactBootstrapBadge,
} from 'react-bootstrap';
import './UserBadges.scss';
import UserBadgeGoldStar from '../../assets/user-badges/badge-gold-star.svg';
import UserBadgeVerified from '../../assets/user-badges/badge-verified.png';

type UserBadgesTypes = {
  subscriptionOnly?: boolean,
  pro?: boolean,
  proPlus?: boolean,
  white?: boolean,
};

export default function UserBadges({ subscriptionOnly, pro, proPlus, white }: UserBadgesTypes) {
  return (
    <div className="pdlUserBadges">
      {!subscriptionOnly && <img height={30} src={UserBadgeVerified} style={{margin: 4}}/>}
      {!subscriptionOnly && <img height={24} src={UserBadgeGoldStar} style={{margin: 4}}/>}
      {(pro || proPlus) && <ProBadge plus={proPlus} white={white}/>}
    </div>
  );
}

/**
 * Refactor Ideas:
 *   ProBadge should be a separate component and lets name it SubscriptionBadgePro
 *   In places where we only need ProBadge we shouldn't use UserBadges component at those places since we only need SubscriptionBadgePro
 *
 *   We should still keep the parameter `white` in the UserBadges Component. That will help us to render all badges nicely in blue containers
 */
function ProBadge({ plus, white } : { plus?: boolean, white?: boolean }) {
  const textColor = white ? '#1877f2' : 'white';
  return (
    <div className="pdlProBadge">
      <ReactBootstrapBadge variant={`${white ? 'light' : 'primary'}`}>
        <div style={{ display: 'flex'}}>
          <div style={{fontSize: 16, fontWeight: 700, color: textColor }}>PRO</div>
          {plus && <div style={{fontSize: 16, marginTop: -4, marginLeft: 2, fontWeight: 700, color: textColor }}>+</div>}
        </div>
      </ReactBootstrapBadge>
    </div>
  );
}
