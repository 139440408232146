import React from 'react';


import './Messenger.scss';
import Container from '../../components/Layout/Container';
import LeftPane from './LeftPane/LeftPane';
import MiddlePane from './MiddlePane/MiddlePane';
import ProfileDrawer from './ProfileDrawer/ProfileDrawer';
import ProjectDetailDrawer from './ProjectDetailDrawer/ProjectDetailDrawer';



export default function Messenger() {
  return (
    <div className="pdlMessengerPage">
      <Container fluid>
        <div style={{display: 'flex'}}>
          <div style={{width: 360}}>
            <LeftPane/>
          </div>
          <div style={{flex: 1, margin: '0px 0px 0px 32px'}}>
            <MiddlePane/>
          </div>
          <ProfileDrawer/>
          <ProjectDetailDrawer/>
        </div>
      </Container>
    </div>
  );
}
