import React, { useContext } from 'react';
import {
  Card as ReactBootstrapCard
} from 'react-bootstrap';

import './Card.scss';
import Button from '../../../../../components/Button/Button';
import RandomSoft from '../../../../../assets/random-sofa.png';
import { MessengerContext } from '../../../../../contexts/MessengerContext';


export default function Card() {
  const { setIsProjectDetailDrawerShown } = useContext(MessengerContext)!;

  return (
    <div className="pdlCard">
      <ReactBootstrapCard>
        <ReactBootstrapCard.Img variant="top" src={RandomSoft}/>
        <ReactBootstrapCard.Body>
          <ReactBootstrapCard.Text>Furniture  cleaning and room decoration</ReactBootstrapCard.Text>
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Button label="View Details" outline onClick={() => setIsProjectDetailDrawerShown(true)}></Button>
          </div>
        </ReactBootstrapCard.Body>
      </ReactBootstrapCard>
    </div>
  );
}
