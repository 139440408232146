import React from 'react';


import './UserRating.scss';
import IconComponent from '../IconComponent';
import Text from '../Text/Text';

type UserRatingTypes = {
  white?: boolean,
};

export default function UserRating({ white }: UserRatingTypes) {
  return (
    <div className="pdlUserRating">
      <div className="pdlStarsContainer">
        <IconComponent name="star_fill" className="pdlStarIcon pdlStarIcon--fill"/>
        <IconComponent name="star_fill" className="pdlStarIcon pdlStarIcon--fill"/>
        <IconComponent name="star_fill" className="pdlStarIcon pdlStarIcon--fill"/>
        <IconComponent name="star_fill" className="pdlStarIcon pdlStarIcon--fill"/>
        <IconComponent name="star_outline" className={`pdlStarIcon ${white && 'pdlStarIcon--outline-white'}`}/>
        <div style={{margin: '0px 4px'}}>
          <Text fontSize={14} white={white}>( 4.0 )</Text>
        </div>
      </div>
    </div>
  );
}
