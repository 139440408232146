import React, {useState} from 'react'


import './Login.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../../components/Text/Text';
import Container from '../../components/Layout/Container';
import LogIn from '../../assets/svg-bootstrap/sign-up.svg';
import InlineInput from '../../components/InlineInput/InlineInput';
import Button from '../../components/Button/Button';
import { FormCheck } from 'react-bootstrap';


export default function Login(){

  const [ passwordType, setPasswordType ] = useState<'text' | 'password'>('password');

  const iconRightOnClick = () =>{
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  }
  return(
    <div className="pdlLoginForm">
      <Container>
        <Row>
          <Col sm={5} className="login">
          <Text fontWeight={600} fontSize={30} bp={true}>Log In into your account</Text>
          <div style={{marginTop: 20}}></div> {/** just for space */}
          <img src={LogIn}/>
          </Col>
          <Col sm={6} className="form">
          <InlineInput type="text" placeholder="Enter your email address or phone number" label="Username"/>
            <div style={{marginTop: 20}}></div> {/** just for space */}
            <InlineInput type={passwordType} placeholder="Enter your password" label="Password" iconRight={passwordType === 'password' ? 'eye_slash' : 'eye'} iconRightOnClick={iconRightOnClick}/>
            <div style={{marginTop: 30}}></div> {/** just for space */}
            <Button label="Log In" block wider l />
            <div className="login-utill">
                <FormCheck className="remember" type="checkbox" label="Remember Me"></FormCheck>
                <p className="forget">Forget Password ?</p>
            </div>
            <div style={{marginTop: 30}}></div> {/** just for space */}
              <Text fontWeight={600} fontSize={20}>Don't have an account ? Create account</Text>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
