import React, { useState } from 'react';


import './Form.scss';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';
import InlineInput from '../../../components/InlineInput/InlineInput';
import SignUp from '../../../assets/svg-bootstrap/sign-up.svg';
import Container from '../../../components/Layout/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


type FormTypes = {
  onSuccess: Function,
}

export default function Form({ onSuccess }: FormTypes) {
  const [ passwordType, setPasswordType ] = useState<'text' | 'password'>('password');

  const iconRightOnClick = () =>{
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  }
  return (
    <div className="pdlRegistrationForm">
      <div className="sign-up">
        <Container>
          <Row>
            <Col sm={5} className="image">
             <h2>Create your account</h2>
             <div style={{marginTop: 20}}></div> {/** just for space */}
              <img src={SignUp}/>
            </Col>
            <Col sm={1}/>
            <Col sm={6}>
              <InlineInput type="text" placeholder="Enter your indivisual or company name" label="Indivisual or Company Name"/>
              <div style={{marginTop: 20}}></div> {/** just for space */} 
              <InlineInput type="email" placeholder="Enter your email" label="Email"/>
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <InlineInput type="tel" placeholder="" label="Mobile Phone"/>
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <InlineInput type={passwordType} placeholder="Enter your password" label="Create a Password" iconRight={passwordType === 'password' ? 'eye_slash' : 'eye'} iconRightOnClick={iconRightOnClick}/>
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <InlineInput type={passwordType} placeholder="Enter your password" label="Confirm Password" iconRight={passwordType === 'password' ? 'eye_slash' : 'eye'} iconRightOnClick={iconRightOnClick}/>
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <InlineInput type="text" placeholder="Enter your postalcode" label="Postal Code"/>
              <div style={{marginTop: 30}}></div> {/** just for space */}
              <Button label="Create account" block wider l onClick={onSuccess}/>
              <div style={{marginTop: 30}}></div> {/** just for space */}
              <div>
              <Text fontWeight={100} fontSize={15} b50={true}>
                *By click "Sign Up" button you will agree with our terms and
                conditions
              </Text>
              <div style={{marginTop: 30}}></div> {/** just for space */}
              <Text fontWeight={600} fontSize={20}>Already have an account ? Log In</Text>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
