import React from 'react';

import {
  Switch,
  Route,
} from 'react-router-dom';

import DesignLibrary from './pages/DesignLibrary/DesignLibrary';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Registration from './pages/Registration/Registration';
import Messenger from './pages/Messenger/Messenger';
import Earning from './pages/Earning/Earning';
import PromotionalPageWrapper from './pages/PromotionalPageTemporary/PromotioonalPageWrapper';


type RouteWithSubRoutesProps = {
  path: string,
  component: any,
  routes?: RouteWithSubRoutesProps[],
};





const AppPaths: RouteWithSubRoutesProps[] = [{
  path: '/design-library/:component',
  component: DesignLibrary,
}, {
  path: '/design-library',
  component: DesignLibrary,
}, {
  path: '/registration',
  component: Registration,
}, {
  path: '/login',
  component: Login,
}, {
  path: '/messenger',
  component: Messenger,
}, {
  path: '/earning',
  component: Earning,
}, {
  path: '/',
  component: PromotionalPageWrapper,
}];





function RouteWithSubRoutes({ path, component, routes }: RouteWithSubRoutesProps) {
  const Element = component;
  return (
    <Route
      path={path}
      render={props => <Element {...props} routes={routes}/>}
    />
  );
}


type RouterSwitchWrapperProps = {
  switchFor: 'app',
}
export function RouterSwitchWrapper({ switchFor }: RouterSwitchWrapperProps) {

  const routes = ({
    'app': AppPaths,
  }[switchFor]);

  return (
    <Switch>
      {routes.map((route: RouteWithSubRoutesProps, i: number) =>
        <RouteWithSubRoutes key={i} {...route} />
      )}
    </Switch>
  );
}
