import React from 'react';


import './MiddlePane.scss';
import Text from '../../../components/Text/Text';
import TopBar from './TopBar/TopBar';
import BottomBar from './BottomBar/BottomBar';
import MessageListContainer from './MessageListContainer/MessageListContainer';



export default function MiddlePane() {
  return (
    <div className="pdlMiddlePane">
      <TopBar/>
      <MessageListContainer/>
      <BottomBar/>
    </div>
  );
}
