import React from 'react';


import './Message.scss';
import Text from '../../../../../../components/Text/Text';
import Avatar from '../../../../../../components/Avatar/Avatar';


type MessengerProps = {
  text: string,
  time: string,
  senderAvatar: string,
  myMessage?: boolean,
}

export default function Message({ text, time, senderAvatar, myMessage }: MessengerProps) {
  return (
    <div className="pdlMessage">
      <div className={`pdlUpper ${myMessage ? 'pdlUpper--right' : 'pdlUpper--left'}`}>
        <div className="pdlAvatarContainer">
          <Avatar src={senderAvatar} rounded h={30} w={30}/>
        </div>
        <SpecialShape left={!myMessage} right={myMessage}/>
        <div className={`pdlMessageTextContainer ${myMessage && 'pdlMessageTextContainer--right'}`}>
          <Text fontSize={16} lineHeight="24px" white={myMessage}>
            {text}
          </Text>
        </div>
      </div>
      <div className={`pdlLower ${myMessage ? 'pdlLower--right' : 'pdlLower--left'}`}>
        <Text
          fontSize={12}
          fontWeight={300}
          b50
        >{time}</Text>
      </div>
    </div>
  )
}

function SpecialShape({ left, right }: { left?: boolean, right?: boolean }) {
  return (
    <div className={`pdlSpecialShape ${left && 'pdlSpecialShape--left'} ${right && 'pdlSpecialShape--right'}`}>
      <div className="pdlCircle">
      </div>
      <div className="pdlCircle pdlCircle--right">
      </div>
    </div>
  );
}
