import React, { useState } from 'react';


import './ChannelCardWork.scss';
import Avatar from '../../../../../components/Avatar/Avatar';
import Text from '../../../../../components/Text/Text';
import UserRating from '../../../../../components/UserRating/UserRating';
import Button from '../../../../../components/Button/Button';


type ChannelCardWorkProps = {
  amount?: number,
  canBid?: boolean,
  onBid?: Function,
  canRebid?: boolean,
  onRebid?: Function,
};

export default function ChannelCardWork({ amount, canBid, onBid, canRebid, onRebid }: ChannelCardWorkProps) {
  const [ isActive, setActive ] = useState<boolean>(false);

  return (
    <div className={`pdlChannelCardWork ${isActive && 'pdlChannelCardWork--isActive'}`}>
      <div className="pdlAvatarContainer">
        <Avatar rounded h={36} w={36} src="https://www.tribeofnoise.com/media/images/profile/44398/1475730433.jpg" onClick={() => setActive(!isActive)}/>
      </div>

      <div className="pdlNameAndStatus">
        <div style={{height: 12}}>{/** Just for space */}</div>
        <Text white={isActive} fontSize={18}>Room Cleaning</Text>
        <div style={{height: 16}}>{/** Just for space */}</div>
        <Text white={isActive} fontSize={14}>Active 2 hours ago</Text>
        <div style={{height: 12}}>{/** Just for space */}</div>
      </div>

      <div className="pdlPriceAndOptions">
        <Text white={isActive} fontSize={24} fontWeight={600} centered>
          {amount ? `${amount}$` : '-'}
        </Text>
        {(canBid || canRebid) && <div style={{height: 4}}></div>}
        {canBid && <Button success label="Bid" onClick={() => onBid?.()}/>}
        {canRebid && <Button label="Rebid" onClick={() => onRebid?.()}/>}
      </div>
    </div>
  );
}
