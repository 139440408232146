import React, { useContext, useState } from 'react';
import {
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';

import './TopBar.scss';
import Avatar from '../../../../components/Avatar/Avatar';
import IconComponent from '../../../../components/IconComponent';
import Text from '../../../../components/Text/Text';
import Button from '../../../../components/Button/Button';
import { MessengerContext } from '../../../../contexts/MessengerContext';
import { BackendContext } from '../../../../contexts/BackendContext';
import UserBadges from '../../../../components/UserBadges/UserBadges';


export default function TopBar() {
  return (
    <div className="pdlTopBar">
      <TopBarLeft/>
      <TopBarRight/>
    </div>
  )
}

function TopBarLeft() {
  const { tempToggleUserRole } = useContext(BackendContext)!;

  return (
    <div className="pdlTopBarLeft">
      <IconComponent name="arrow_left" className="pdlBackButtonIcon" onClick={() => {}}/>
      <Avatar rounded h={44} w={44} src="https://www.tribeofnoise.com/media/images/profile/44398/1475730433.jpg" onClick={tempToggleUserRole}/>
      <div style={{marginLeft: 14}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Text
              fontSize={18}
              lineHeight="27px"
              fontWeight={500}
            >
            John Doe
          </Text>
          <UserBadges proPlus/>
        </div>

        <Text
          fontSize={12}
          lineHeight="18px"
          fontWeight={300}
        >
          Last seen 1 day ago
        </Text>
      </div>
    </div>
  );
}

function TopBarRight() {
  const { user } = useContext(BackendContext)!;
  const { setIsProfileDrawerShown, setIsProjectDetailDrawerShown } = useContext(MessengerContext)!;
  const [ negotiatePopoverShown, setNegotiatePopoverShown ] = useState<boolean>(false);
  const [ negotiateAmount, setNegotiateAmount ] = useState<string>('');

  return (
    <div className="pdlTopBarRight">
      <IconComponent name="three_dots_vertical" className="tripleDotsIcon" onClick={() => user?.role === 'user' ? setIsProfileDrawerShown(true) : setIsProjectDetailDrawerShown(true)}/>

      <div style={{margin: '0px 8px 0px 8px'}}>
        <OverlayTrigger trigger="click" placement="bottom" show={negotiatePopoverShown} overlay={
          <Popover id="popover-basic">
            <Popover.Content>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 16}}>
                <div style={{display: 'flex', margin: 16}}>
                  <Text fontSize={16} fontWeight={600}>
                    CAD
                  </Text>
                  <input
                    placeholder="Ex: 100"
                    value={negotiateAmount} onChange={({ target }) => setNegotiateAmount(target.value)}
                    style={{width: 56, border: 'none', outline: 'none', borderBottom: '1px solid black', marginBottom: 8, marginLeft: 8}}
                  />
                </div>
                <Button label="Send"/>
              </div>
            </Popover.Content>
          </Popover>
        }>
          <Button warning label="Negotiate" onClick={() => setNegotiatePopoverShown((cv) => !cv)}/>
        </OverlayTrigger>
      </div>

      {user?.role === 'user' && <Text centered>Or</Text>}

      {user?.role === 'user' &&
        <div style={{margin: '0px 8px 0px 8px'}}>
          <Button label="Place Order"/>
        </div>
      }

      <div style={{margin: '0px 24px 0px 0px'}}>
        <Text
          fontSize={35}
          fontWeight={600}
        >$180</Text>
      </div>

    </div>
  );
}
