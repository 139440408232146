import React, { useState } from 'react';


import './ProjectImages.scss';
import Text from '../../../../../components/Text/Text';
import IconComponent from '../../../../../components/IconComponent';


function negMod(x: number, m: number) { return ((x%m)+m)%m; }

export default function ProjectImages() {
  const imageUrls = [
    "https://empire-s3-production.bobvila.com/slides/17060/widened/white-sofa.jpg?1591219993",
    "https://static.independent.co.uk/s3fs-public/thumbnails/image/2018/12/20/12/leather-sofa-lifestyle-rex.jpg?width=1200",
    "https://images.unsplash.com/photo-1567016557389-5246a1940bdc?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjJ8fHNvZmF8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&w=1000&q=80",
  ];
  const [ firstImage, setFirstImage ] = useState<number>(0);

  return (
    <div className="pdlProjectImages">

      <div className="pdlSlider">
        <SideImage
          toLeft h={116} w={61}
          onClick={() => setFirstImage(negMod(firstImage - 1, imageUrls.length))}
          src={imageUrls[firstImage]}/>
        <img
          style={{ borderRadius: 8 }} height={136} width={236}
          src={imageUrls[negMod(firstImage + 1, imageUrls.length)]}/>
        <SideImage
          h={116} w={61}
          onClick={() => setFirstImage(negMod(firstImage + 1, imageUrls.length))}
          src={imageUrls[negMod(firstImage + 2, imageUrls.length)]}/>
      </div>

      <Text
        fontSize={22}
        lineHeight="32px"
        fontWeight={500}
        centered
      >
        Furniture Cleaning
      </Text>

      <div style={{height: 16}}>{/** Just for gap */}</div>
    </div>
  );
}

function SideImage({ toLeft, src, h, w, onClick }: { toLeft?: boolean, src: string, h: number, w: number, onClick?: Function }) {
  const borderStyles = {
    borderTopRightRadius: (toLeft ? 8 : 0), borderBottomRightRadius: (toLeft ? 8 : 0),
    borderTopLeftRadius: (!toLeft ? 8 : 0), borderBottomLeftRadius: (!toLeft ? 8 : 0),
  };

  return (
    <div
      style={{
        height: h,
        width: w,
        cursor: 'pointer'
      }}
      onClick={() => onClick?.()}
    >
      <img
        height={h}
        width={w}
        src={src}
        style={{
          ...borderStyles
        }}
      />
      <div
        style={{
          height: h,
          width: w,
          ...borderStyles,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: 8,
          position: 'relative',
          top: -(h)
        }}
      > </div>
      <div
        style={{
          height: 64,
          width: 64,
          ...borderStyles,
          padding: 8,
          position: 'relative',
          top: -(204),

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconComponent name={toLeft ? 'arrow_left' : 'arrow_right'} className="pdlArrowIconStyle"/>
      </div>
    </div>
  );
}
