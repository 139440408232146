import React from 'react';


import './SuccessMessage.scss';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';
import { ReactComponent as WhiteTik } from './SuccessTik.svg';


type SuccessMessageTypes = {
  onSuccess: Function,
}

export default function SuccessMessage({ onSuccess }: SuccessMessageTypes) {

  return (
    <div className="pdlSuccessMessage">
      <Text
        fontSize={30}
        fontWeight={500}
        centered>
        Successful !
      </Text>

      <div style={{height: 20}}>{/** just for gap */}</div>

      <div className="pdlTikContainer">
        <WhiteTik/>
      </div>

      <div style={{height: 32}}>{/** just for gap */}</div>

      <Text centered fontSize={14} lineHeight="21px" b50>
        You successfully completed you registration. Thank You
      </Text>

      <div style={{height: 72}}>{/** just for gap */}</div>

      <Button block label="Complete your profile" onClick={onSuccess}/>
    </div>
  );
}
