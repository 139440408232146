import React from 'react';


import './QuoteMessageContainer.scss';


type QuoteMessageContainerProps = {
  children: React.ReactChild[],
  highlighted?: boolean,
};

export default function QuoteMessageContainer({ children, highlighted }: QuoteMessageContainerProps) {
  return (
    <div className={`pdl-Messenger-Message-QuoteMessageContainer ${highlighted && 'pdl-Messenger-Message-QuoteMessageContainer--highlighted'}`}>
      {children}
    </div>
  );
}