import React from 'react';
import { Carousel as ReactBootstrapCarousel } from 'react-bootstrap';

import './Carousel.scss';
import Text from '../Text/Text';


export default function Carousel() {
  const titles = [
    'Any Service, Any Time, Anywhere.', 'Your Personal Assistant', 'One-stop solution for your services.',
  ]
  const images = [
    'https://www.androidpolice.com/wp-content/uploads/2020/12/21/galaxy-s21-wallpapers-leak-hero.jpg',
    'https://c4.wallpaperflare.com/wallpaper/911/774/372/black-cubes-wallpaper-preview.jpg',
    'https://i.pinimg.com/originals/9d/7f/b1/9d7fb1baf1666c66a652f1de9a0f703e.jpg',
  ]

  return (
    <div className="pdlCarousel">
      <ReactBootstrapCarousel controls={false}>
        {images.map((imageUrl, index) =>
          <ReactBootstrapCarousel.Item key={index} interval={6000}>
            <img
              className="d-block w-100"
              height={484}
              src={imageUrl}
              alt={`Slide Indes: ${index}`}
            />

            <ReactBootstrapCarousel.Caption>
              <Text fontSize={54} fontWeight={600} white>
                {titles[index]}
              </Text>
            </ReactBootstrapCarousel.Caption>
          </ReactBootstrapCarousel.Item>
        )}
      </ReactBootstrapCarousel>
    </div>
  );
}
