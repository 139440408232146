import React from 'react';

import { ReactComponent as ArrowLeft } from '../assets/svg-material/keyboard_arrow_left-24px.svg';
import { ReactComponent as ArrowRight } from '../assets/svg-material/keyboard_arrow_right-24px.svg';
import { ReactComponent as Search } from '../assets/svg-material/search-24px.svg';
import { ReactComponent as LocationOn } from '../assets/svg-material/location_on-24px.svg';
import { ReactComponent as CallMade } from '../assets/svg-material/call_made-24px.svg';
import { ReactComponent as PlayArrow } from '../assets/svg-material/play_arrow-24px.svg';
import { ReactComponent as Call } from '../assets/svg-material/call-24px.svg';
import { ReactComponent as Email } from '../assets/svg-material/email-24px.svg';
import { ReactComponent as LocationOnOutline } from '../assets/svg-material/location_on_outline-24px.svg';
import { ReactComponent as NavigationFill } from '../assets/svg-material/navigation_fill-24px.svg';
import { ReactComponent as FilterAltFilled } from '../assets/svg-material/filter_alt_filled-24px.svg';

import { ReactComponent as CameraFill } from '../assets/svg-bootstrap/camera-fill.svg';
import { ReactComponent as StarFill } from '../assets/svg-bootstrap/star-fill.svg';
import { ReactComponent as StarHalf } from '../assets/svg-bootstrap/star-half.svg';
import { ReactComponent as StarOutline } from '../assets/svg-bootstrap/star-outline.svg';
import { ReactComponent as ThreeDotsVertical } from '../assets/svg-bootstrap/three-dots-vertical.svg';
import { ReactComponent as EmojiSmileFill } from '../assets/svg-bootstrap/emoji-smile-fill.svg';

import { ReactComponent as FlatIconFilter } from '../assets/svg-flaticon/filter.svg';
import { ReactComponent as FlatIconFolder } from '../assets/svg-flaticon/folder.svg';
import { ReactComponent as FlatIconCamera } from '../assets/svg-flaticon/camera.svg';
import { ReactComponent as FlatIconEdit } from '../assets/svg-flaticon/edit.svg';

import { ReactComponent as CatFood } from '../assets/svg-category-icons/food.svg';
import { ReactComponent as CatRental } from '../assets/svg-category-icons/rental.svg';
import { ReactComponent as CatPhoto } from '../assets/svg-category-icons/photo.svg';
import { ReactComponent as CatBeauty } from '../assets/svg-category-icons/beauty.svg';
import { ReactComponent as CatTailor } from '../assets/svg-category-icons/tailor.svg';
import { ReactComponent as CatWedding } from '../assets/svg-category-icons/wedding.svg';

import { ReactComponent as HappyFacesQuoteStart } from '../assets/happy-face-quote-start.svg';
import { ReactComponent as HappyFacesQuoteEnd } from '../assets/happy-face-quote-end.svg';

import { ReactComponent as Facebook } from '../assets/svg-social-media-icons/facebook.svg';
import { ReactComponent as Twitter } from '../assets/svg-social-media-icons/twitter.svg';
import { ReactComponent as Instagram } from '../assets/svg-social-media-icons/instagram.svg';

import { ReactComponent as EyeSlash } from '../assets/svg-bootstrap/eye-slash.svg';
import { ReactComponent as Eye } from '../assets/svg-bootstrap/eye.svg';
import { ReactComponent as PrinterFill } from '../assets/svg-bootstrap/printer-fill.svg';


export type SVGIconNames =
  'arrow_left' |
  'arrow_right' |
  'cat_food' |
  'cat_rental' |
  'cat_photo' |
  'cat_beauty' |
  'cat_tailor' |
  'cat_wedding' |
  'happy_face_quote_start' |
  'happy_face_quote_end' |
  'search' |
  'location_on' |
  'call_made' |
  'play_arrow' |
  'facebook' |
  'twitter' |
  'instagram' |
  'call' |
  'email' |
  'location_on_outline' |
  'camera_fill' |
  'eye_slash' |
  'eye' |
  'printer_fill' |
  'filter_alt_filled' |
  'star_fill' |
  'star_half' |
  'star_outline' |
  'three_dots_vertical' |
  'emoji_smile_fill' |
  'flat_icon_filter'|
  'flat_icon_folder' |
  'flat_icon_camera' |
  'flat_icon_edit' |
  'navigation_fill'
;

export type IconComponentProps = {
  name: SVGIconNames,
  className: string,
  onClick?: Function,
};

export default function IconComponent({ name, className, onClick }: IconComponentProps) {

  const IconComponentToRender = {
    arrow_left: ArrowLeft,
    arrow_right: ArrowRight,
    cat_food: CatFood,
    cat_rental: CatRental,
    cat_photo: CatPhoto,
    cat_beauty: CatBeauty,
    cat_tailor: CatTailor,
    cat_wedding: CatWedding,
    happy_face_quote_start: HappyFacesQuoteStart,
    happy_face_quote_end: HappyFacesQuoteEnd,
    search: Search,
    location_on: LocationOn,
    call_made: CallMade,
    play_arrow: PlayArrow,
    facebook: Facebook,
    twitter: Twitter,
    instagram: Instagram,
    call: Call,
    email: Email,
    location_on_outline: LocationOnOutline,
    camera_fill: CameraFill,
    eye_slash: EyeSlash,
    eye : Eye,
    printer_fill: PrinterFill,
    filter_alt_filled: FilterAltFilled,
    star_fill: StarFill,
    star_half: StarHalf,
    star_outline: StarOutline,
    three_dots_vertical: ThreeDotsVertical,
    emoji_smile_fill: EmojiSmileFill,
    flat_icon_filter: FlatIconFilter,
    flat_icon_folder: FlatIconFolder,
    flat_icon_camera: FlatIconCamera,
    flat_icon_edit: FlatIconEdit,
    navigation_fill: NavigationFill,
  }[name];

  return (
    <IconComponentToRender className={className} onClick={(e) => onClick?.()}/>
  );
}
