import React from 'react';


import './SearchBar.scss';
import IconComponent from '../../../../../components/IconComponent';


export default function SearchBar() {
  return (
    <div className="pdl-msg-SearchBar">
      <IconComponent name="search" className="pdlSearchIcon"/>
      <input placeholder="Search here..."/>
    </div>
  );
}
