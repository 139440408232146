import React from 'react';

import { Image as ReactBootstrapImage } from 'react-bootstrap';
import './Avatar.scss';


type AvatarProps = {
  bg?: boolean,
  disabled?: boolean,
  rounded?: boolean,
  h: number,
  w: number,
  src: string,
  onClick?: Function,
}

export default function Avatar({ bg, disabled, rounded, h, w, src, onClick }: AvatarProps) {
  return (
    <div
      className={`pdlAvatar ${bg && 'pdlAvatar--bg'}`}
      style={{
        height: h,
        width: w,
        borderRadius: '100%',
        cursor: onClick ? 'pointer' : 'auto'
      }}
      onClick={() => onClick?.()}
    >
      <ReactBootstrapImage
        height={ h - (bg ? 16 : 0) }
        width={ w - (bg ? 16 : 0) }
        src={src}
        roundedCircle={rounded}
        rounded={!rounded}
      />
      {disabled &&
        <div
          style={{
            height: h - (bg ? 16 : 0),
            width: w - (bg ? 16 : 0),
            borderRadius: rounded ? '100%' : 2,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            padding: 8,
            position: 'relative',
            top: -(h - (bg ? 16 : 0))}}
        > </div>
      }
    </div>
  );
}
