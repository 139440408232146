import React from 'react';

import {
  Table as ReactBootstrapTable
} from 'react-bootstrap';

import './Earning.scss';
import Text from '../../components/Text/Text';
import Button from '../../components/Button/Button';
import IconComponent from '../../components/IconComponent';


export default function Earning() {
  return (
    <div className="pdl-EarningPageContainer">
      <div className="pdl-EarningTableCard">

        <div className="pdl-Earning-TopBar">
          <div style={{ width: 76 }}>{/** empty div, to make Earning title middle */}</div>
          <Text fontWeight={500} fontSize={30}>
            Earning
          </Text>
          <div style={{ padding: 20 }}>
            <PrintButton/>
          </div>
        </div>

        <Table/>
      </div>
    </div>
  );
}

function PrintButton() {
  return (
    <Button
      label={
        <div style={{ display: 'flex', alignItems:'center' }}>
          <Text white>
            Print
          </Text>
          <div style={{width: 12}}></div>
          <IconComponent name="printer_fill" className=""/>
        </div>
      }
      onClick={() => window.print()}
    />
  );
}

function Table() {
  const dummyData = {
    date: 'February 27, 2021',
    orderNumber: '#56331',
    orderTitle: 'Furniture  cleaning',
    amount: '$100',
    status: true,
  }

  const dummyList = [
    dummyData, dummyData, { ...dummyData, status: false }, dummyData, dummyData, dummyData, dummyData, dummyData, dummyData,
    dummyData, dummyData, dummyData, dummyData, dummyData, dummyData, dummyData, dummyData, { ...dummyData, status: false },
  ];

  return (
    <div className="pdl-Earning-Table">
      <ReactBootstrapTable>
        <thead>
          <tr className="table-secondary">
            <th scope="col">
              <Text fontWeight={500} fontSize={18} centered>Date</Text>
            </th>
            <th scope="col">
              <Text fontWeight={500} fontSize={18} centered>Order Number</Text>
            </th>
            <th scope="col">
              <Text fontWeight={500} fontSize={18} centered>Order Title</Text>
            </th>
            <th scope="col">
              <Text fontWeight={500} fontSize={18} centered>Amount</Text>
            </th>
            <th scope="col">
              <Text fontWeight={500} fontSize={18} centered>Status</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {dummyList.map(item =>
            <tr>
              <td>
                <Text fontWeight={300} fontSize={16} centered>{item.date}</Text>
              </td>
              <td>
                <Text fontWeight={300} fontSize={16} centered>{item.orderNumber}</Text>
              </td>
              <td>
                <Text fontWeight={300} fontSize={16} centered>{item.orderTitle}</Text>
              </td>
              <td>
                <Text fontWeight={300} fontSize={16} centered>{item.amount}</Text>
              </td>
              <td>
                <Text fontWeight={300} fontSize={16} centered success={item.status} danger={!item.status}>
                  {item.status ? 'Paid' : 'Unpaid'}
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </ReactBootstrapTable>
    </div>
  );
}
